@font-face {
    font-family: 'Trade Gothic';
    src: url('TradeGothicLTBold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Trade Gothic';
    src: url('TradeGothicLTBoldCondensedNo.20.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Trade Gothic';
    src: url('TradeGothicLTBoldCondensedNo.20Oblique.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Trade Gothic';
    src: url('TradeGothicLTCondensedNo.18.ttf') format('ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Trade Gothic';
    src: url('TradeGothicLTCondensedNo.18Oblique.ttf') format('ttf');
    font-weight: 300;
    font-style: italic;
}