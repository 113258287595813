.font {
  &.xl {
    font-size: 24px;
  }

  &.lg {
    font-size: 18px;
  }

  &.md {
    font-size: 16px;
  }

  &.sm {
    font-size: 14px;
  }
}


