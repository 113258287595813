#card-input {
  font-family: var(--typography);
  font-size: var(--font_md);
  color: var(--text);
  background-color: var(--input_bg);
  border: var(--border_sm) var(--input_border);
  border-radius: var(--radius_sm);
  box-shadow: var(--shadow_inset_xs);
  height: 40px;
  box-sizing: border-box;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

#card-input::placeholder {
  color: var(--text_a50);
}

#card-input.is-focused {
  box-shadow: var(--shadow_xs);
}

#card-input.is-invalid {
border-color: var(--danger);
}

#card-error {
color: var(--danger);
  padding: 4px 0;
  font-size: 14px;
}

#payment-form {
  margin: 16px 0 0 0;
}

#submit-button {
  background-color: var(--primary);
  color: #FFF;
  border: 3px solid;
  border-radius: 9999px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  cursor: "pointer";
  width: 100%;
  padding: 12px;
  margin: 10px 0 0 0;
  font-family: var(--typography);
  text-transform: '';
  text-transform: uppercase;
}