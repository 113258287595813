@font-face {
    font-family: 'Neutra Text';
    src: url('NeutraText-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Neutra Text';
    src: url('NeutraText-Italic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Neutra Text';
    src: url('NeutraText-Medium.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Neutra Text';
    src: url('NeutraText-Regular.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Neutra Text';
    src: url('NeutraText-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Neutra Text';
    src: url('NeutraText-MediumItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

