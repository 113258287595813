@font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk Bold'), local('MonumentGrotesk-Bold'),
        url('MonumentGrotesk-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk Italic'), local('MonumentGrotesk-Italic'),
        url('MonumentGrotesk-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk Medium'), local('MonumentGrotesk-Medium'),
        url('MonumentGrotesk-Medium.woff2') format('woff2'),;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk Regular'), local('MonumentGrotesk-Regular'),
        url('MonumentGrotesk-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk Bold Italic'), local('MonumentGrotesk-BoldItalic'),
        url('MonumentGrotesk-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: local('Monument Grotesk Medium Italic'), local('MonumentGrotesk-MediumItalic'),
        url('MonumentGrotesk-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

