.labelContact {
  @apply flex;
  @apply flex-row;
  @apply pt-2;

  &-item {
    @apply pr-3;
    margin-top: 0.5px;
  }
  &-arrowdown {
    @apply pl-2;
    cursor: pointer;
  }

  &-hours {
    @apply pl-8;
    @apply pt-1;
  }
}
