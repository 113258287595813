.logo {
  @apply grid;
  @apply w-full;
  @apply grid-cols-1;
  &-item {
    @apply p-4;
    @apply flex;
    @apply justify-center;
  }
}

