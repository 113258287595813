:root {
  --toastify-color-light: var(--background);
  --toastify-color-dark: var(--text);
  --toastify-color-info: var(--yumminn_40);
  --toastify-color-success: var(--yumminn_40);
  --toastify-color-warning: var(--warning_20);
  --toastify-color-error: var(--danger_20);
  --toastify-color-transparent: rgba(255, 255, 255, 0.5);
  --toastify-toast-background: var(--background);

  --toastify-icon-color-info: currentColor;
  --toastify-icon-color-success: currentColor;
  --toastify-icon-color-warning: currentColor;
  --toastify-icon-color-error: currentColor;

  --toastify-toast-width: 100%;

  @media (orientation: landscape) {
    --toastify-toast-width: auto;
  }

  --toastify-toast-min-height: 60px;
  --toastify-toast-max-height: 100%;
  
  --toastify-font-family: var(--typography);
  
  --toastify-z-index: 9999;

  --toastify-text-color-light: var(--text_dark);
  --toastify-text-color-dark: var(--text_light);
  --toastify-text-color-info: var(--text_dark);
  --toastify-text-color-success: var(--text_dark);
  --toastify-text-color-warning: var(--text_dark);
  --toastify-text-color-error: var(--text_dark);
  --toastify-spinner-color: currentColor;
  --toastify-spinner-color-empty-area: var(--gray_50);
  --toastify-color-progress-light: var(--point_gradient);
  --toastify-color-progress-dark: var(--point_gradient);
  --toastify-color-progress-info: var(--yumminn);
  --toastify-color-progress-success: var(--yumminn);
  --toastify-color-progress-warning: var(--warning);
  --toastify-color-progress-error: var(--danger);
}

.Toastify__toast {
  font-size: 0.875rem;
  border-radius: 5px;
  margin: 24px;
}

.Toastify__close-button {
  color: currentColor;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info{
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success{
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning{
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__toast-body > *,
.Toastify__close-button > * {
  pointer-events: none;
}

/* New receipt toast */

.Toastify__toast-container--top-left,
.Toastify__toast-container--top-center,
.Toastify__toast-container--top-right {
  top: 44px; /* header height */

  @media (orientation: landscape) {
    top: 0;
  }
}

#toast_notify_receipt {
  margin: 8px 24px;
  padding: 0;
  border-radius: var(--radius_sm);
  min-height: 0;
}

#toast_notify_receipt .Toastify__toast-body {
  padding: 0;
  margin: 0;
  height: fit-content;
}

#toast_notify_receipt .Toastify__toast-body > * {
  pointer-events: all;
}