.adyen-checkout__button--pay {
    font-size: 14px;
    line-height: 14px;
    font-family: var(--typography);
    border-radius: 33px !important;
    background-color: #5FB894;
    font-weight: 600;
    text-transform: uppercase;
}

.adyen-checkout__card-input {
    width: 100%;
}
