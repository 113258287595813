@font-face {
    font-family: 'Silka';
    src: url('silka-regular-webfont.eot');
    src: url('silka-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-regular-webfont.woff2') format('woff2'),
         url('silka-regular-webfont.woff') format('woff'),
         url('silka-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Silka';
    src: url('silka-regularitalic-webfont.eot');
    src: url('silka-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('silka-regularitalic-webfont.woff2') format('woff2'),
         url('silka-regularitalic-webfont.woff') format('woff'),
         url('silka-regularitalic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}